import { ReactElement, useState, useEffect } from "react";
import {
    Typography,
    Table,
    Button,
    Divider,
    theme,
    Tooltip,
    Modal,
    message,
    TableProps,
} from "antd";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
    useQuoteRequestDetails,
    useQuoteRequests,
    useQuoteRequestsCount,
} from "app/utils/api/queries/quote_requests";
import { _isEmpty, DateUtils, pluralize } from "app/utils/helpers";
import { format, formatISO } from "date-fns";
import { BackendReturnType } from "app/types/data/data";
import LoadingIndicator from "app/components/elements/loading_indicator/loading_indicator";
import URL from "app/constants/route_urls";
import { ListingQuoteRequestType, ListingType } from "app/types";
import PageWithLoader from "app/hoc/page_with_loader";
import listingsSlice from "app/store/listings/listings.slice";
import userSlice from "app/store/user/user.slice";
import QuoteRequestsFilters, {
    PARAM_TYPE,
    QuoteType,
} from "app/views/protected/business_dashboard/quote_requests/quote_requests_filters";
import { getEmailHelpLink } from "app/utils/helpers/get_email_help_link";
import ObscuredQuoteWrapper from "./obscured_quote_wrapper";
import "./quote_requests.scss";
import STORAGE_CONSTANTS from "app/constants/storage";
import Storage from "app/utils/storage/local";
import { OpenEnvelopeOutlined } from "assets";
import { useBulkdMarkRead } from "app/utils/api/mutations/quote_requests.mutation";

export interface IQuoteRequest {
    key: string | number;
    id: string | number;
    from: string;
    email: string;
    phone: string;
    dateReceived: string | null;
    dateViewed: string | null;
    dateExpires: string | null;
    hasExpired: boolean | null;
}

const isQuoteExpired = (str: string | null | undefined): boolean =>
    Boolean(new Date(str as string) < new Date());

// TODO -> Once a quote is viewed update the table since this indication doesn't work
const prepareTableData = (listing: ListingType, quotes: ListingQuoteRequestType[] = []) => {
    const dataSource = quotes?.map(
        (quote: ListingQuoteRequestType): IQuoteRequest => ({
            key: quote.pk,
            id: quote.pk,
            from: quote.name,
            email: quote.email,
            phone: quote.phone,
            dateReceived: quote.date_received,
            dateViewed: quote.date_viewed,
            dateExpires: quote.date_expires,
            hasExpired: Boolean(new Date(quote.date_expires as string) < new Date()),
        })
    );

    const columns = [
        {
            title: "From",
            dataIndex: "from",
            key: "from",
            render: (from: string, quote: IQuoteRequest): JSX.Element => (
                <ObscuredQuoteWrapper text={from} listing={listing} showTooltip={false} />
            ),
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            render: (email: string, { hasExpired }: { hasExpired: boolean }) => (
                <ObscuredQuoteWrapper
                    text={email}
                    listing={listing}
                    textHidden={hasExpired}
                    showTooltip={false}
                />
            ),
        },
        {
            title: "Phone",
            dataIndex: "phone",
            textWrap: "word-break",
            render: (phone: string, { hasExpired }: { hasExpired: boolean }) => (
                <ObscuredQuoteWrapper
                    text={phone}
                    listing={listing}
                    textHidden={hasExpired}
                    showTooltip={false}
                />
            ),
        },
        {
            title: "Received on",
            dataIndex: "dateReceived",
            key: "dateReceived",
            render: (date: string): string => DateUtils.formatDateString(date, false) || "-",
        },
        {
            title: "Viewed on",
            dataIndex: "dateViewed",
            key: "dateViewed",
            render: (date: string): string =>
                date ? DateUtils.formatDateString(date, false) : "-",
        },
        {
            title: "Expires in",
            dataIndex: "dateExpires",
            key: "dateExpires",
            render: (date: string): string =>
                DateUtils.daysUntil(date) <= 0
                    ? "Expired"
                    : `${DateUtils.daysUntil(date)} ${pluralize("day", DateUtils.daysUntil(date))}`,
        },
    ];
    return {
        dataSource,
        columns,
    };
};

const VendorQuoteRequests = (): ReactElement => {
    const navigate = useNavigate();
    const params = useParams();
    const [searchParams] = useSearchParams();
    const queryClient = useQueryClient();
    const user = userSlice((state) => state.user);
    const { token } = theme.useToken();
    const { currentListingSelection, listingsCount, listing, setCurrentListing } = listingsSlice(
        (state) => state
    );
    const isPremiumListing = listing?.is_premium;

    const [modalOpen, setModalOpen] = useState<boolean>(false);
    const [currentQuote, setCurrentQuote] = useState<number | undefined>(undefined);
    const [currentPage, setCurrentPage] = useState<number>(1);
    const [emailError, setEmailError] = useState(false);
    const [selectedRowIds, setSelectedRowIds] = useState<(string | number)[]>([]);

    const isListingEnabled: boolean = currentListingSelection !== undefined;
    const quoteType: QuoteType | null | string = searchParams.get(PARAM_TYPE);
    const emptyQuotesMessage = quoteType
        ? `You have no ${quoteType} quote requests`
        : "You have no quote requests";

    const {
        data: quoteDetails,
        isLoading: quoteDetailsLoading,
        isPlaceholderData: quoteDetailsIsPlaceHolderData,
        error: quoteDetailsError,
    } = useQuoteRequestDetails(currentQuote, {
        enabled: currentQuote !== undefined,
    });
    const { data: unreadQuotesCount } = useQuoteRequestsCount(currentListingSelection, false, {
        enabled: currentListingSelection !== undefined,
    });

    const isDetailsLoading = quoteDetailsIsPlaceHolderData || quoteDetailsLoading;

    const { data, error, isError, isLoading, isPlaceholderData } = useQuoteRequests(
        currentListingSelection,
        currentPage,
        quoteType,
        {
            enabled: isListingEnabled,
        }
    );

    const { mutate: bulkMarkRead, isPending: isBulkMarkingRead } = useBulkdMarkRead({});

    const { dataSource, columns } = prepareTableData(listing, data?.results);
    const isQuoteRequests = data && data?.count > 1 ? "requests" : "request";

    useEffect(() => {
        if (params?.id) {
            setCurrentQuote(+params.id);

            // if the quote isn't in current listing switch to relevant listing
            if (!_isEmpty(quoteDetails) && !(quoteDetails.listing.pk === currentListingSelection)) {
                setCurrentListing(quoteDetails.listing.pk);
                Storage.set(
                    `${STORAGE_CONSTANTS.currentListing}.${user?.email}`,
                    quoteDetails.listing.pk.toString()
                );
            }
        }
    }, [currentListingSelection, params.id, quoteDetails, setCurrentListing, user?.email]);

    const quoteNotFound = quoteDetailsError?.response.status === 404;

    // Display error and redirect user if quote is not found
    useEffect(() => {
        if (quoteNotFound) {
            setCurrentQuote(undefined);
            if (params?.id) navigate(URL.BUSINESS_QUOTES, { replace: true });
            message.error("Quote request not found");
        }
    }, [navigate, params?.id, quoteNotFound]);

    const optimisticQuoteUpdate = (quoteId: number) => {
        const queryKey = ["vendor_quote_requests", currentListingSelection, currentPage, quoteType];
        const currentData: BackendReturnType<ListingQuoteRequestType[]> = queryClient.getQueryData(
            queryKey
        ) || { results: [] };

        const currentQuote = currentData.results?.find(
            (quote: ListingQuoteRequestType) => quote.pk === quoteId
        );

        queryClient.setQueryData(
            queryKey,
            (previous: BackendReturnType<ListingQuoteRequestType[]>) => ({
                ...previous,
                results: previous?.results?.map((item: ListingQuoteRequestType) =>
                    item.pk === currentQuote?.pk
                        ? { ...item, date_viewed: formatISO(new Date()) }
                        : item
                ),
            })
        );
    };

    const createEmailBody = () => {
        const userName = quoteDetails?.name;
        const userEmail = quoteDetails?.email;
        const receivedDate = DateUtils.formatDateString(quoteDetails?.date_received as string);
        const content = quoteDetails?.content;

        // Split the content into lines and add ">" at the beginning of each line
        const contentWithQuotes = content!
            .split("\n")
            .map((line) => `> ${line}`)
            .join("\n");

        const emailBody = `> From: ${userName} (${userEmail})\n> Received on: ${receivedDate}\n>\n${contentWithQuotes}`;

        return `mailto:${quoteDetails?.email}?subject=${encodeURIComponent(
            `Re: Quote request for ${listing?.primary_category.short_description}`
        )}&body=${encodeURIComponent(`\n\n${emailBody}`)}`;
    };

    const handleReply = () => {
        const emailBody = createEmailBody();

        // Set a flag to track whether the link was clicked
        let linkClicked = false;

        // Open the email link
        window.location.href = emailBody;

        // Set a timeout for checking if the browser responded
        const timeoutDuration = 2000;
        const timeoutId = setTimeout(() => {
            if (!linkClicked) {
                setEmailError(true);
            }
        }, timeoutDuration);

        // Event listener to detect when the link is clicked
        window.addEventListener("beforeunload", () => {
            linkClicked = true;
            clearTimeout(timeoutId); // Clear the timeout since the browser has responded
        });
    };

    const quoteExpiryText = (expiryDate: string, isExpired: boolean): string =>
        !isExpired
            ? `This quote will expire on ${DateUtils.formatDateString(expiryDate)}`
            : "This quote has expired";

    return (
        <PageWithLoader
            isLoading={isLoading && data === undefined}
            error={isError}
            errorText={error?.message}
        >
            <div className="vendor-quote-requests">
                <div className="page__header">
                    <Typography.Title level={1}>Quote requests</Typography.Title>
                    <Typography.Text>A list of quote requests you have received.</Typography.Text>
                </div>
                <div className="page__toolbar">
                    {data?.count ? (
                        <Typography.Text strong>
                            {data?.count} quote {isQuoteRequests}{" "}
                            {unreadQuotesCount > 0 ? `(${unreadQuotesCount} unread)` : ""}
                        </Typography.Text>
                    ) : null}

                    <Button
                        onClick={() => {
                            if (selectedRowIds && currentListingSelection) {
                                bulkMarkRead({
                                    quotes: selectedRowIds,
                                    id: currentListingSelection,
                                    page: currentPage,
                                    quoteType,
                                });
                            }
                        }}
                        className="vendor-quote-requests__mark-as-read-button"
                        disabled={!selectedRowIds.length || isBulkMarkingRead}
                        type="default"
                        loading={isBulkMarkingRead}
                    >
                        <OpenEnvelopeOutlined />
                        Mark as read
                    </Button>
                </div>
                {_isEmpty(data?.results) && !isLoading ? (
                    <div className="vendor-quote-requests__empty">
                        {listingsCount === 0 ? (
                            <>
                                <Typography.Text>You have no listings to display.</Typography.Text>
                                <Typography.Text>
                                    <span
                                        role="presentation"
                                        className="link"
                                        onClick={() => navigate(URL.BUSINESS_LISTINGS_ADD)}
                                    >
                                        Add a listing
                                    </span>{" "}
                                    to start using your business dashboard tools.
                                </Typography.Text>
                            </>
                        ) : (
                            <Typography.Text>{emptyQuotesMessage}</Typography.Text>
                        )}
                    </div>
                ) : (
                    <div
                        className={`vendor-quote-requests__content ${
                            isPlaceholderData ? "isPlaceholderData" : ""
                        }`}
                    >
                        <div className="vendor-quote-requests__table">
                            <Table
                                rowSelection={{
                                    type: "checkbox",
                                    onChange: (selectedRowKeys, selectedRows) => {
                                        setSelectedRowIds(selectedRows.map((r) => r.id));
                                    },
                                    getCheckboxProps: (record) => ({
                                        // disabled: false,
                                    }),
                                    selectedRowKeys: selectedRowIds,
                                    // Toggle selected row when clicking on the cell, not only the checkbox
                                    onCell: (record) => ({
                                        onClick: (e) => {
                                            e.stopPropagation();

                                            if (selectedRowIds.includes(record.id)) {
                                                setSelectedRowIds((prev) =>
                                                    prev.filter((id) => id !== record.id)
                                                );
                                            } else {
                                                setSelectedRowIds((prev) => [...prev, record.id]);
                                            }
                                        },
                                    }),
                                }}
                                dataSource={dataSource}
                                columns={columns as any}
                                // scroll={{ x: "max-content" }}
                                pagination={{
                                    position: ["bottomCenter"],
                                    total: data?.count,
                                    showSizeChanger: false,
                                    current: currentPage,
                                    defaultPageSize: data?.page_size,
                                    responsive: true,
                                    size: "default",
                                    hideOnSinglePage: true,
                                    onChange: (page: number): void => {
                                        setCurrentPage(page);
                                        window.scrollTo({ top: 0, behavior: "smooth" });
                                    },
                                }}
                                onRow={(record) => ({
                                    onClick: () => {
                                        setCurrentQuote(record.key as number);
                                        setModalOpen(true);
                                    },
                                    className: `table__clickable-row ${record.dateViewed || record.hasExpired ? "vendor-quote-requests__viewed-row" : "vendor-quote-requests__not-viewed-row"}`,
                                })}
                                size="small"
                            />
                        </div>
                    </div>
                )}

                <Modal
                    open={!quoteNotFound && (modalOpen || Boolean(currentQuote))}
                    centered
                    width={480}
                    style={{
                        minHeight: isDetailsLoading ? "50rem" : "auto",
                    }}
                    title={
                        <>
                            <Typography.Title
                                level={4}
                                style={{ marginBottom: "0.25rem", fontSize: "1.8rem" }}
                                className="vendor-quote-requests__modal-title"
                            >
                                Quote request from{" "}
                                <ObscuredQuoteWrapper
                                    text={quoteDetails?.name}
                                    listing={listing}
                                    fontSize="18px"
                                />
                            </Typography.Title>
                            <Typography.Text
                                type="secondary"
                                style={{ fontWeight: "400", fontSize: "1.4rem" }}
                            >
                                Received on{" "}
                                {DateUtils.formatDateString(
                                    quoteDetails?.date_received as string,
                                    true
                                )}
                            </Typography.Text>
                        </>
                    }
                    onCancel={() => {
                        setCurrentQuote(undefined);
                        optimisticQuoteUpdate(currentQuote as number);
                        queryClient.invalidateQueries({
                            queryKey: ["vendor_quote_requests_count", currentListingSelection],
                        });
                        if (params?.id) navigate(URL.BUSINESS_QUOTES, { replace: true });
                        setModalOpen(false);
                    }}
                    className="vendor-quote-requests__modal"
                    footer={
                        !isDetailsLoading
                            ? [
                                  <div className="vendor-quote-requests__modal-footer" key="1">
                                      {/* eslint-disable-next-line no-nested-ternary */}
                                      {isPremiumListing ? (
                                          <Button
                                              key="1-1"
                                              block
                                              type="primary"
                                              onClick={handleReply}
                                              disabled={isQuoteExpired(quoteDetails?.date_expires)}
                                          >
                                              Reply to quote request
                                          </Button>
                                      ) : !isQuoteExpired(quoteDetails?.date_expires) ? (
                                          <Button
                                              key="1-2"
                                              block
                                              type="primary"
                                              onClick={() => {
                                                  navigate(
                                                      `${URL.LISTING_UPGRADE}/${listing?.uuid}/`,
                                                      {
                                                          state: { listing },
                                                      }
                                                  );
                                              }}
                                          >
                                              Upgrade to reply
                                          </Button>
                                      ) : null}
                                      {emailError && (
                                          <div
                                              className="vendor-quote-requests__email-error"
                                              key="1-3"
                                          >
                                              <Typography.Text>
                                                  Button not working? Check out{" "}
                                                  <a
                                                      href={getEmailHelpLink()}
                                                      target="_blank"
                                                      rel="noreferrer"
                                                      className="link"
                                                  >
                                                      these instructions
                                                  </a>{" "}
                                                  to fix it.
                                              </Typography.Text>
                                          </div>
                                      )}
                                  </div>,
                                  <Typography.Text style={{ fontSize: "1.3rem" }} key="2    ">
                                      {quoteExpiryText(
                                          quoteDetails?.date_expires as string,
                                          isQuoteExpired(quoteDetails?.date_expires)
                                      )}
                                  </Typography.Text>,
                              ]
                            : []
                    }
                >
                    <div className="vendor-quote-requests__modal-content">
                        {isDetailsLoading ? (
                            <LoadingIndicator />
                        ) : (
                            <>
                                <div className="vendor-quote-requests__top">
                                    <div className="vendor-quote-requests__top-item row">
                                        <Typography.Text strong>To:</Typography.Text>
                                        <Typography.Text>{listing.title}</Typography.Text>
                                    </div>
                                    <div className="vendor-quote-requests__top-item row">
                                        <Typography.Text strong>From:</Typography.Text>

                                        <Typography.Text>
                                            <ObscuredQuoteWrapper
                                                text={quoteDetails?.name}
                                                listing={listing}
                                                fontSize="15px"
                                                padding={0}
                                            />
                                        </Typography.Text>
                                    </div>
                                    {!isQuoteExpired(quoteDetails?.date_expires) && (
                                        <>
                                            <div className="vendor-quote-requests__top-item row">
                                                <Typography.Text strong>Email:</Typography.Text>
                                                <Typography.Text>
                                                    <ObscuredQuoteWrapper
                                                        text={quoteDetails?.email}
                                                        textHidden={isQuoteExpired(
                                                            quoteDetails?.date_expires
                                                        )}
                                                        listing={listing}
                                                        fontSize="15px"
                                                        padding={0}
                                                    />
                                                </Typography.Text>
                                            </div>
                                            <div className="vendor-quote-requests__top-item row">
                                                <Typography.Text strong>Phone:</Typography.Text>
                                                <Typography.Text>
                                                    <ObscuredQuoteWrapper
                                                        text={quoteDetails?.phone}
                                                        textHidden={isQuoteExpired(
                                                            quoteDetails?.date_expires
                                                        )}
                                                        listing={listing}
                                                        fontSize="15px"
                                                        padding={0}
                                                    />
                                                </Typography.Text>
                                            </div>
                                        </>
                                    )}
                                    <div className="vendor-quote-requests__top-item row">
                                        <Typography.Text strong>Message:</Typography.Text>
                                        <Typography.Text>
                                            {isPremiumListing
                                                ? quoteDetails?.content
                                                : quoteDetails?.content_redacted}
                                        </Typography.Text>
                                    </div>
                                </div>
                                {isPremiumListing && (
                                    <>
                                        <Divider style={{ margin: "1.2rem 0" }} />
                                        <div className="vendor-quote-requests__bottom">
                                            {!_isEmpty(quoteDetails?.offer) && (
                                                <div className="vendor-quote-requests__bottom-item">
                                                    <Typography.Paragraph strong>
                                                        This quote request relates to the following
                                                        offer:
                                                    </Typography.Paragraph>
                                                    <Typography.Text>
                                                        {quoteDetails?.offer?.title}
                                                    </Typography.Text>
                                                </div>
                                            )}

                                            {!_isEmpty(quoteDetails?.race) && (
                                                <>
                                                    <Divider style={{ margin: "1.2rem 0" }} />
                                                    <div
                                                        className="vendor-quote-requests__bottom-item"
                                                        style={{ borderRadius: token.borderRadius }}
                                                    >
                                                        <Typography.Paragraph
                                                            strong
                                                            style={{ marginBottom: "1rem" }}
                                                        >
                                                            The following race details were attached
                                                            to this request:
                                                        </Typography.Paragraph>
                                                        <ul className="vendor-quote-requests__bottom-list">
                                                            <li className="vendor-quote-requests__bottom-list-item">
                                                                Race name:{" "}
                                                                {quoteDetails?.race?.name}
                                                            </li>
                                                            <li className="vendor-quote-requests__bottom-list-item">
                                                                Race date:{" "}
                                                                {format(
                                                                    new Date(
                                                                        quoteDetails?.race
                                                                            ?.start_date || ""
                                                                    ),
                                                                    "MMMM d, yyyy"
                                                                )}
                                                            </li>
                                                            <li className="vendor-quote-requests__bottom-list-item">
                                                                Race location:{" "}
                                                                {quoteDetails?.race?.location}
                                                            </li>
                                                            <li className="vendor-quote-requests__bottom-list-item">
                                                                Approx. number of participants:{" "}
                                                                {
                                                                    quoteDetails?.race
                                                                        ?.num_participants
                                                                }
                                                            </li>
                                                        </ul>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </>
                                )}
                            </>
                        )}
                    </div>
                </Modal>
            </div>
        </PageWithLoader>
    );
};

export default VendorQuoteRequests;
