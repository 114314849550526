import { ReactElement, useState, useEffect } from "react";
import { Typography, Input, Select, Row, Col, Form, Button, Upload, message } from "antd";
import type { UploadFile, UploadProps } from "antd";
import { useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { useParams, useNavigate } from "react-router-dom";
import { yupResolver } from "@hookform/resolvers/yup";
import { ListingType } from "app/types";
import { getBase64 } from "app/utils/helpers/getBase64";
import { InternetOutlined, EmailOutlined, PhoneOutlined, PlusOutlined } from "assets";
import { businessListingSchema } from "app/lib/validation_schemas/business_listing.schema";
import { useVendorListingDetails, useVendorListingsFormData } from "app/utils/api/queries";
import { tryCatch } from "app/utils/helpers/functional_utilities";
import { AxiosServiceError } from "app/utils/api/axios/service_error";
import { _isEmpty, imageValidator } from "app/utils/helpers";
import PageWithLoader from "app/hoc/page_with_loader";
import API_URL from "app/constants/api_urls";
import API from "app/utils/api/axios";
import URL from "app/constants/route_urls";
import "./listing.scss";
import { ChevronDownOutlined } from "rdhq-icons";

type Payload = {
    category?: number;
    companyName: string;
    description: string;
    email: string;
    thumbnail_image: { base64: string };
    phone: string;
    website: string;
};

const BusinessListing = (): ReactElement => {
    const params = useParams();
    const navigate = useNavigate();
    const queryClient = useQueryClient();
    const [isSaving, setIsSaving] = useState<boolean>(false);
    const [fileList, setFileList] = useState<UploadFile[]>([]);
    const { data, isLoading, isError } = useVendorListingsFormData();
    const {
        data: details,
        isLoading: isLoadingDetails,
        isError: isErrorDetails,
        error: errorDetails,
    } = useVendorListingDetails(params?.id as string | number, {
        enabled: params !== undefined && params.id !== undefined,
    });
    const editMode = Boolean(params?.id);
    const [messageApi, contextHolder] = message.useMessage();
    const formTitle = params?.id ? details?.title : "Add listing";

    const {
        register,
        handleSubmit,
        setValue,
        setError,
        clearErrors,
        watch,
        reset,
        getValues,
        formState: { errors },
    } = useForm({
        resolver: yupResolver(businessListingSchema),
        mode: "onBlur",
        reValidateMode: "onBlur",
    });

    useEffect(() => {
        if (editMode && details !== undefined) {
            if (details?.thumbnail_image)
                setFileList([{ url: details.thumbnail_image!, uid: "1", name: "" }]);
            reset({
                category: details.categories?.at(0)!.pk,
                companyName: details?.title,
                description: details?.description as string,
                website: details?.contact_website as string,
                phone: details?.contact_phone as string,
                email: details?.contact_email as string,
                thumbnail_image: { base64: (details?.thumbnail_image as string) || undefined },
            });
        }
    }, [params?.id, reset, details?.pk, details]);

    const handleChange: UploadProps["beforeUpload"] = async (file): Promise<boolean> => {
        const [isValid, errorMessage] = imageValidator(file);
        if (!isValid) {
            messageApi.error(errorMessage as string);
            return false;
        }

        const fileUrl = await getBase64(file);
        const prepareFile = {
            uid: file.uid,
            name: file.name,
            url: fileUrl,
        };
        setFileList([prepareFile]);
        setValue("thumbnail_image", { base64: prepareFile.url });
        clearErrors("thumbnail_image");
        return false;
    };

    const submitPayload = async (payload: {
        title: string;
        description: string;
        thumbnail_image: { base64: string };
        contact_website: string;
        contact_email: string;
        contact_phone: string;
    }): Promise<ListingType> => {
        try {
            if (details?.thumbnail_image === payload.thumbnail_image.base64)
                Reflect.deleteProperty(payload, "thumbnail_image");

            const request = !editMode
                ? API.post(API_URL.BUSINESS_LISTINGS_ADD, payload)
                : API.patch(`${API_URL.BUSINESS_LISTINGS_EDIT}/${params?.id}/edit/`, payload);
            const { data } = await request;
            return data;
        } catch (err) {
            throw new AxiosServiceError(err);
        }
    };

    const submitHandler = async ({
        companyName,
        description,
        email,
        thumbnail_image,
        phone,
        website,
    }: Payload) => {
        setIsSaving(true);
        const preparePayload = {
            title: companyName,
            description,
            thumbnail_image,
            contact_website: website,
            contact_email: email,
            contact_phone: phone,
        };

        const [error, result] = await tryCatch(submitPayload)(preparePayload);
        if (error) {
            setIsSaving(false);
            messageApi.error("Something went wrong while saving your listing. Please try again.");
            return;
        }

        if (!editMode) {
            queryClient.setQueryData(["vendor_listings"], (prevData: ListingType[]) => [
                ...prevData,
                result,
            ]);
        }

        queryClient.invalidateQueries({
            queryKey: ["vendor_listings"],
        });

        setIsSaving(false);
        const message = (title: string): string =>
            editMode
                ? "Your listing edits have been received and are under review"
                : `${title} has been successfully added`;
        navigate(`${URL.BUSINESS_LISTINGS}?success=${message(result?.title as string)}`);
    };

    // Handle page title
    useEffect(() => {
        if (editMode && !_isEmpty(details)) {
            document.title = `Edit ${details?.title}`;
        }
    }, [details, params?.id]);

    return (
        <PageWithLoader
            isLoading={isLoadingDetails || isLoading}
            error={isErrorDetails}
            errorText={errorDetails?.message}
        >
            <div className="business-listing">
                {contextHolder}
                <Form
                    key={details?.pk}
                    className="business-listing__wrap"
                    layout="vertical"
                    onFinish={handleSubmit(submitHandler)}
                >
                    <div className="business-listing__section business-info">
                        <div className="business-listing__main-heading">
                            <Typography.Title>{formTitle}</Typography.Title>
                            <Typography.Paragraph type="secondary">
                                Fields marked with (<span>*</span>) are required
                            </Typography.Paragraph>
                        </div>
                        <div className="business-listing__title">
                            <Typography.Title level={4}>Business info</Typography.Title>
                        </div>
                        <Row gutter={[24, 8]} style={{ marginBottom: "2rem" }}>
                            <Col span={editMode ? 12 : 24}>
                                <Typography.Text className="ant-label" strong>
                                    <span>Company name</span>
                                    <span className="ant-label-required">*</span>
                                </Typography.Text>
                                <Input
                                    suffix={<span />}
                                    status={`${errors?.companyName ? "error" : ""}`}
                                    value={watch("companyName")}
                                    {...register("companyName")}
                                    onChange={(event) =>
                                        setValue("companyName", event.target.value)
                                    }
                                />
                                {errors?.companyName && (
                                    <Typography.Text className="ant-error-label">
                                        {errors.companyName.message}
                                    </Typography.Text>
                                )}
                            </Col>
                            {editMode && (
                                <Col span={24} md={12}>
                                    <Typography.Text className="ant-label" strong>
                                        <span>Category</span>
                                        <span className="ant-label-required">*</span>
                                    </Typography.Text>
                                    <Select
                                        style={{ width: "100%" }}
                                        status={`${errors?.category || isError ? "error" : ""}`}
                                        suffixIcon={<ChevronDownOutlined />}
                                        loading={isLoading}
                                        disabled
                                        value={watch("category")}
                                        {...register("category")}
                                        onChange={(value) => {
                                            setValue("category", +value);
                                        }}
                                        options={data?.categories.map((category) => ({
                                            label: category.name,
                                            value: category.pk,
                                        }))}
                                    />
                                    {errors?.category && (
                                        <Typography.Text className="ant-error-label">
                                            {errors.category.message}
                                        </Typography.Text>
                                    )}
                                </Col>
                            )}
                        </Row>
                        <Row gutter={[24, 8]} style={{ marginBottom: "2rem" }}>
                            <Col span={24}>
                                <Typography.Text className="ant-label" strong>
                                    <span>Description</span>
                                    <span className="ant-label-required">*</span>
                                </Typography.Text>
                                <Input.TextArea
                                    placeholder=""
                                    rows={5}
                                    status={`${errors.description ? "error" : ""}`}
                                    value={watch("description")}
                                    {...register("description")}
                                    onChange={(event) =>
                                        setValue("description", event.target.value)
                                    }
                                />
                                {errors?.description && (
                                    <Typography.Text className="ant-error-label">
                                        {errors.description.message}
                                    </Typography.Text>
                                )}
                            </Col>
                        </Row>
                        <Row gutter={[24, 8]}>
                            <Col span={24}>
                                <div className="business-listing__logo-image-wrap">
                                    <Typography.Text
                                        strong
                                        className="ant-label"
                                        data-label="upload-label"
                                    >
                                        Business logo (<span className="ant-label-required">*</span>
                                        )
                                    </Typography.Text>
                                    <div
                                        className={`upload-dragger-wrap ${
                                            errors?.thumbnail_image?.base64 ? "isError" : ""
                                        }`}
                                    >
                                        <Upload
                                            accept="image/png, image/jpeg, image/jpg"
                                            maxCount={1}
                                            listType="picture-card"
                                            multiple={false}
                                            fileList={fileList}
                                            onRemove={() => setFileList([])}
                                            beforeUpload={handleChange}
                                            showUploadList={{
                                                showPreviewIcon: false,
                                            }}
                                            onChange={(info) => {
                                                if (info.fileList.length === 0) {
                                                    setError("thumbnail_image.base64", {
                                                        type: "manual",
                                                        message: "Logo is required",
                                                    });
                                                } else {
                                                    clearErrors("thumbnail_image.base64");
                                                }
                                            }}
                                        >
                                            <button
                                                className="business-listing__upload-btn"
                                                type="button"
                                            >
                                                <PlusOutlined />
                                                <div style={{ marginTop: 8 }}>Upload</div>
                                            </button>
                                        </Upload>
                                        <Typography.Text>
                                            Max file size: 2mb | Accepted: .jpg, .jpeg, .png
                                        </Typography.Text>
                                        {errors?.thumbnail_image?.base64 && (
                                            <Typography.Text className="ant-error-label">
                                                {errors.thumbnail_image.base64.message}
                                            </Typography.Text>
                                        )}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </div>
                    <div className="business-listing__section contact-information">
                        <div className="business-listing__title">
                            <Typography.Title level={4}>Contact info</Typography.Title>
                        </div>
                        <Row gutter={[24, 8]} style={{ marginBottom: "2rem" }}>
                            <Col span={24} md={12}>
                                <Typography.Text className="ant-label" strong>
                                    <span>Website</span>
                                    <span className="ant-label-required">*</span>
                                </Typography.Text>
                                <Input
                                    placeholder=""
                                    prefix={<InternetOutlined />}
                                    status={`${errors?.website ? "error" : ""}`}
                                    value={watch("website")}
                                    {...register("website")}
                                    onChange={(event) => setValue("website", event.target.value)}
                                />
                                {errors?.website && (
                                    <Typography.Text className="ant-error-label">
                                        {errors.website.message}
                                    </Typography.Text>
                                )}
                            </Col>
                            <Col span={24} md={12}>
                                <Typography.Text className="ant-label" strong>
                                    <span>Contact email</span>
                                    <span className="ant-label-required">*</span>
                                </Typography.Text>
                                <Input
                                    placeholder=""
                                    prefix={<EmailOutlined />}
                                    status={`${errors?.email ? "error" : ""}`}
                                    value={watch("email")}
                                    {...register("email")}
                                    onChange={(event) => setValue("email", event.target.value)}
                                />
                                {errors?.email && (
                                    <Typography.Text className="ant-error-label">
                                        {errors.email.message}
                                    </Typography.Text>
                                )}
                            </Col>
                        </Row>
                        <Row gutter={[24, 8]}>
                            <Col span={24} md={12}>
                                <Typography.Text className="ant-label" strong>
                                    <span>Contact phone</span>
                                    <span className="ant-label-required">*</span>
                                </Typography.Text>
                                <Input
                                    placeholder=""
                                    prefix={<PhoneOutlined />}
                                    status={`${errors?.phone ? "error" : ""}`}
                                    value={watch("phone")}
                                    {...register("phone")}
                                    onChange={(event) => setValue("phone", event.target.value)}
                                />
                                {errors?.phone && (
                                    <Typography.Text className="ant-error-label">
                                        {errors.phone.message}
                                    </Typography.Text>
                                )}
                            </Col>
                        </Row>
                    </div>
                    <div className="business-listing__cta">
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={isSaving}
                            disabled={isSaving}
                            onClick={() => {
                                const values = getValues();
                                if (!Reflect.has(values, "logo")) {
                                    setError("thumbnail_image.base64", {
                                        type: "manual",
                                        message: "Logo is required",
                                    });
                                }
                            }}
                        >
                            {editMode ? "Submit edits" : "Submit for approval"}
                        </Button>
                    </div>
                </Form>
            </div>
        </PageWithLoader>
    );
};

export default BusinessListing;
